define(['app', '$window', '$location'], function (app, $window, $location) {

  const offlineBanner = function () {
    let self = this;
    self.app = app;

    const HIDE_OFFLINE_BANNER_CLASS_NAME = 'hideOfflineBanner';
    const _config = {
      offlineBannerButton: '[data-js-element=offlineBanner_button]'
    };

    const _init = (element) => {
      self.element = element;
      self.offlineBannerButton = self.element.querySelector(self.config.offlineBannerButton);
      self.addEventListeners();

      return self;
    };

    const _addEventListeners = () => {
      $window.addEventListener('online', self.handleConnection);
      $window.addEventListener('offline', self.handleConnection);
      self.offlineBannerButton.addEventListener('click', self.reloadPage);
    };

    const _handleConnection = () => {
      if (navigator.onLine && !self.element.classList.contains(HIDE_OFFLINE_BANNER_CLASS_NAME)) {
        self.element.classList.add(HIDE_OFFLINE_BANNER_CLASS_NAME);
      } else if (!navigator.onLine && self.element.classList.contains(HIDE_OFFLINE_BANNER_CLASS_NAME)) {
        self.element.classList.remove(HIDE_OFFLINE_BANNER_CLASS_NAME);
      }
    };

    const _reloadPage = () => {
      $location.reload();
    }

    self.config = _config;
    self.init = _init;
    self.addEventListeners = _addEventListeners;
    self.handleConnection = _handleConnection;
    self.reloadPage = _reloadPage;
  };

  return offlineBanner;
});
